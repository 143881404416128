import React from 'react'
import get from 'lodash.get'
import { dateFormat } from '../../utils'
import { getLocale } from '../../utils/location'
import useWindowSize from '../../hooks/useWindowSize'
import './style.scss'

const getThumbStyle = (srcWidth, srcHeight, maxWidth, maxHeight) => {
  var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight)
  return { width: srcWidth * ratio, height: srcHeight * ratio }
}

const EventsList = ({ events }) => {
  const localeFromContent = get(events, '[0].node._meta.lang', null)

  const wSize = useWindowSize()
  const locale = getLocale(localeFromContent)

  return (
    <div className="events">
      {events.map(({ node }) => {
        if (!node) {
          return null
        }

        const title = get(node, 'title[0].text', null)
        const eventTitle = get(node, 'title_event[0].text', null)
        const date = get(node, 'date', null)
        const articleUrl = `/${locale}/article/${node._meta.uid}`
        const eventThumbnail = get(node, 'thumbnail_event', null)
        const defaultThumbnail = get(node, 'thumbnail', null)
        const thumbnail = eventThumbnail || defaultThumbnail
        let thumbStyle = {}
        
        if (!!thumbnail) {
          const thumbMaxHeight = wSize.height * 0.6
          const thumbMaxWidth = wSize.width * 0.6
          thumbStyle = getThumbStyle(
            thumbnail.dimensions.width,
            thumbnail.dimensions.height,
            thumbMaxWidth,
            thumbMaxHeight
          )
        } else {
          thumbStyle = {}
        }
        
        const label = get(node, 'label[0].text')
        const artist = get(node, 'artist[0].text')

        return (
          <div key={node._meta.id}>
            <div className="event">
              <a href={articleUrl}>
                <div className="event__content">
                  <h2 className="event__title ft-subtitle">
                    <span>{eventTitle || title}</span>
                    {!!thumbnail && (
                      <img
                        className="event__image"
                        src={thumbnail.url}
                        alt={thumbnail.alt}
                        style={thumbStyle}
                      />
                    )}
                  </h2>
                  {date && <em>{dateFormat(date)}</em>}
                  <span>{label}</span>
                  {artist && <span>{artist}</span>}
                </div>
              </a>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default EventsList
