import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash.get'
import Layout from '../layouts/fr'
import SEO from '../components/seo'
import Events from '../views/Events'

const EventsPage = ({ location, data }) => {
  const articles = get(data, 'prismic.events.edges')
  const events = articles.filter(article => !!article.node.is_in_agenda)
  console.log("events", events)

  return (
    <Layout location={location}>
      <SEO title="Events" />
      <Events events={events} />
    </Layout>
  )
}

export const query = graphql`
  query {
    prismic {
      events: allArticles(sortBy: date_DESC, lang: "fr-fr") {
        edges {
          node {
            _meta {
              id
              uid
              lang
            }
            date
            thumbnail
            thumbnail_event
            title
            title_event
            label
            artist
            is_in_agenda
          }
        }
      }
    }
  }
`

export default EventsPage
